var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-2"},[_c('div',{staticClass:"mt-2"},[_c('vxe-button',{staticClass:"px-1 py-0",attrs:{"status":"my-primary","size":"mini","content":"添加策略"},on:{"click":function($event){return _vm.addStrategy()}}}),_c('span',{staticClass:"text--secondary pl-2",staticStyle:{"font-size":"12px"}},[_vm._v("可添加多个策略混合")])],1),_c('div',{staticClass:"mt-2"},[_c('vxe-table',{ref:"strategyTable",attrs:{"id":"strategyTable","row-id":"id","data":_vm.strategyArr,"border":false,"resizable":"","show-overflow":"","show-header-overflow":"","highlight-hover-row":"","align":"left","column-config":{ resizable: true },"height":"500px","size":"small","sort-config":{ trigger: 'cell', multiple: true },"empty-render":{ name: 'NotData' },"filter-config":{ showIcon: false },"expand-config":{ trigger: 'row' }}},[_c('vxe-column',{attrs:{"min-width":"70","field":"code","title":"策略名","type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.method ? row.method : row.name)+" ")]}},{key:"content",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [_c('div',{staticClass:"pa-2"},_vm._l((row.options),function(item,index){return _c('div',[_vm._v(" "+_vm._s(index)+" : "+_vm._s(item)+" ")])}),0)]}}])}),_c('vxe-column',{attrs:{"title":"操作","type":"seq","width":"80","resizable":false,"show-overflow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('a',{staticClass:"text-xs font-weight-normal text-typo",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.deleteStrategy(row)}}},[_vm._v("删除")])])]}}])})],1)],1),_c('div',[_c('div',{staticClass:"my-1 d-flex justify-space-between align-center"},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"13px"}},[_vm._v("回测起始日期")]),_c('vxe-input',{attrs:{"placeholder":"回测起始日期","type":"date","size":"mini"},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1),_c('div',{staticClass:"my-1 d-flex justify-space-between align-center"},[_c('span',{staticClass:"mr-2",staticStyle:{"font-size":"13px"}},[_vm._v("回测结束日期")]),_c('vxe-input',{attrs:{"placeholder":"回测结束日期","type":"date","size":"mini"},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1),_c('vxe-button',{staticClass:"px-1 py-0 my-2",attrs:{"status":"primary","content":"开始回测"},on:{"click":function($event){return _vm.submit()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div style="width: 100%" class="mt-2">
    <div
      class="px-6 d-flex justify-space-between align-center"
      style="width: 100%"
    >
      <h3 class="my-2">分笔交易图</h3>
      <div>
        <span class="text--secondary mr-2" style="font-size: 13px"
          >选择视图大小</span
        >
        <vxe-select
          v-model="timeSetting"
          placeholder="视图大小"
          size="mini"
          style="width: 80px"
        >
          <vxe-option value="day" label="日"></vxe-option>
          <vxe-option value="week" label="周"></vxe-option>
          <vxe-option value="month" label="月"></vxe-option>
        </vxe-select>
      </div>
    </div>
    <div
      ref="FirmTradeChart"
      style="width: 850px; margin-left: 25px"
      :style="{ height: '300px' }"
    />
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import moment from "moment";

import gantt from "dhtmlx-gantt"; // 引入模块
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";

export default {
  name: "TradeChart",
  components: {},
  data() {
    return {
      myChart: null,
      pageHeight: 0,

      stockNameList: [],
      stockCodeList: [],
      data: {
        data: [],
      },

      timeSetting: "week",
    };
  },
  computed: {},
  mounted() {
    this.pageHeight = document.body.offsetHeight;
    setTimeout(() => {
      this.getData();
    }, 500);
  },
  watch: {
    timeSetting(newValue) {
      gantt.config.scale_unit = newValue;

      if (newValue == "week" || newValue == "year") {
        gantt.config.date_scale = "%y年%M"; //时间轴上的时间格式
      } else {
        gantt.config.date_scale = "%M%d日"; //时间轴上的时间格式
      }
      gantt.render();
    },
  },
  beforeDestroy() {
    console.log("销毁甘特图");
    gantt.ext.tooltips.tooltip.hide();
    gantt.clearAll();
  },
  methods: {
    getData() {
      var types = [
        { name: "盈利", color: "#EF534F" },
        { name: "亏损", color: "#2AA49D" },
        { name: "无盈亏", color: "#aaaaaa" },
        // { name: "持有", color: "#e0bc78" },
      ];

      //初始化股票列表
      this.appStore.currentFirmDetail.stockList.forEach((stock) => {
        this.stockNameList.push(stock.stock_name);
        this.stockCodeList.push(stock.stock_code);
      });

      this.appStore.firmBackTestData.round_trip.forEach((stock) => {
        //确定这个股票在stockCodeList中的index
        let stock_index = this.stockCodeList.indexOf(stock.code);

        //初始化个股的交易记录
        //根据stock.code在stockList中找到对应的stock_name
        let stock_name = this.stockNameList[stock_index];

        //遍历买入记录，生成交易记录
        stock.datein.forEach((date, dateIndex) => {
          //对比pricein和priceout，确定盈利还是亏损
          let typeItem;
          if (stock.pricein[dateIndex] < stock.priceout[dateIndex]) {
            typeItem = types[0];
          } else if (stock.pricein[dateIndex] > stock.priceout[dateIndex]) {
            typeItem = types[1];
          } else {
            typeItem = types[2];
          }
          let parent = 0;
          let tradeRow = null;
          if (dateIndex == 0) {
            tradeRow = {
              id: `${stock.code}0all`,
              text: `${stock_name}`,
              start_date: stock.datein[dateIndex],
              color: "#409EFF",
              parent: `0`,
              render: "split",
              type: "project",
              data: {
                stock_code: stock.code,
                stock_name: stock_name,
                pricein: stock.pricein[dateIndex],
                priceout: stock.priceout[dateIndex],
                returns: stock.returns[dateIndex],
              },
            };
            this.data.data.push(tradeRow);
          }
          tradeRow = {
            id: `${stock.code}${dateIndex}`,
            text: `${stock_name}`,
            start_date: stock.datein[dateIndex],
            end_date: stock.dateout[dateIndex],
            duration: stock.nbars[dateIndex],
            color: typeItem.color,
            parent: `${stock.code}0all`,
            data: {
              stock_code: stock.code,
              stock_name: stock_name,
              pricein: stock.pricein[dateIndex],
              priceout: stock.priceout[dateIndex],
              returns: stock.returns[dateIndex],
            },
          };
          this.data.data.push(tradeRow);
        });
      });

      this.drawLine();
    },
    //绘制回测图
    drawLine() {
      let getTradeColor = (val) => {
        let tradeColor = "#EF534F";
        if (val < 0) {
          tradeColor = "#2AA49D";
        } else if (val === 0) {
          tradeColor = "#999999";
        }
        return tradeColor;
      };
      gantt.config.xml_date = "%Y-%m-%d";
      // 在时间线上增加一行年份显示
      gantt.config.subscales = [
        {
          unit: "year",
          step: 1,
          date: "%Y",
        },
      ];
      // 初始化
      //表格列设置
      gantt.config.columns = [
        {
          name: "text",
          label: "成分股",
          align: "left",
          tree: false,
          width: "140",
          onrender: function (task, node) {
            node.setAttribute(
              "class",
              "gantt_cell gantt_last_cell gantt_cell_tree " + task.status
            );
          },
        },
      ];
      gantt.plugins({
        tooltip: true,
      });
      gantt.attachEvent("onGanttReady", () => {
        var tooltips = gantt.ext.tooltips;
        gantt.templates.tooltip_text = (start, end, task) => {
          if (task.id.indexOf("all") != -1) {
            return `成分股：${task.text}<br/>`;
          } else {
            return `
            成分股：${task.text}<br/>
            买入时间：${gantt.templates.tooltip_date_format(start)}<br/>
            卖出时间：${gantt.templates.tooltip_date_format(end)}<br/>
            买入价：${task.data.pricein.toFixed(
              2
            )}，卖出价：${task.data.priceout.toFixed(2)}<br/>
            收益率：<span style="color:${getTradeColor(task.data.returns)}">${(
              task.data.returns * 100
            ).toFixed(2)}%</span><br/>
          `;
          }
        };
      });
      gantt.templates.task_text = function (start, end, task) {
        return "";
      };
      //任务条上的文字大小 以及取消border自带样式
      gantt.templates.task_class = function (start, end, item) {
        return "ganttTask";
      };
      gantt.config.dynamic_resource_calendars = true;
      //gantt.config.autosize = true; //自适应甘特图的尺寸大小, 使得在不出现滚动条的情况下, 显示全部任务
      gantt.config.prevent_default_scroll = true;
      gantt.plugins({
        click_drag: true,
        drag_timeline: true, // 拖动图
        fullscreen: true, // 全屏
        tooltip: true, // 鼠标经过时信息
      });

      gantt.config.start_on_monday = true; // 第一天将变为周日
      gantt.config.autofit = false;
      gantt.config.show_task_cells = false; //时间轴图表中，如果不设置，只有行边框，区分上下的任务，设置之后带有列的边框，整个时间轴变成格子状。
      gantt.config.fit_tasks = true; //自动调整任务的宽度，使得任务不会超出甘特图的宽度
      gantt.config.task_height = 20; //时间轴图表中，甘特图的高度
      gantt.config.row_height = 22; //时间轴图表中，甘特图的高度
      gantt.config.scale_height = 30; //时间轴图表中，时间刻度的高度
      gantt.config.readonly = true; //只读模式
      //gantt.config.smart_scales = true;
      gantt.config.scale_unit = "week"; //时间轴以月为单位
      gantt.config.date_scale = "%y年%M"; //时间轴上的时间格式
      gantt.i18n.setLocale("cn");
      gantt.init(this.$refs.FirmTradeChart);
      // 数据解析
      gantt.parse(this.data);
    },
  },
};
</script>
<style>
.ganttTask {
  border: none !important;
}
</style>

<template>
  <v-container fluid class="px-1 py-2">
    <div
      style="width: calc(100vw - 90px); display: flex; justify-content: center"
      v-if="showPage"
    >
      <div style="width: 1280px; background-color: #fff">
        <div style="width: 100%; display: flex">
          <div style="width: 900px; border: 1px solid #ccc">
            <div
              v-if="!showBackTest"
              style="width: 100%; font-size: 40px; color: #ccc"
              :style="{ height: pageHeight - 80 + 'px' }"
              class="d-flex justify-center align-center"
            >
              添加成分股后即可开始回测
            </div>
            <BackTest v-if="showBackTest" key="backtest"></BackTest>
          </div>

          <div style="width: 380px; border: 1px solid #ccc">
            <!-- 股票信息 -->
            <div class="d-flex justify-space-between align-center px-2 pt-4">
              <div>
                <span class="text-dark">{{ firmInfo.name }}</span>
              </div>
            </div>
            <div class="px-1 pb-2">
              <vxe-radio-group v-model="main_tab" size="mini" class="mt-2">
                <vxe-radio-button label="1" content="成分股"></vxe-radio-button>
                <vxe-radio-button
                  label="2"
                  content="策略回测"
                ></vxe-radio-button>
              </vxe-radio-group>
              <div class="mt-2">
                <!-- 成分股 -->
                <div
                  v-show="main_tab == 1"
                  class="pa-1"
                  style="border: 1px solid #ccc"
                >
                  <vxe-toolbar class="px-3">
                    <template v-slot:buttons>
                      <vxe-input
                        v-model="filterName"
                        type="search"
                        placeholder="全文搜索"
                        class="mr-1"
                        style="width: 100px"
                      ></vxe-input>
                      <vxe-button
                        class="px-1 py-0 ml-2"
                        status="my-primary"
                        @click="addStock()"
                        size="mini"
                        content="添加成分股"
                      ></vxe-button>
                    </template>
                  </vxe-toolbar>
                  <vxe-table
                    id="stockTable"
                    row-id="id"
                    ref="stockTable"
                    :data="list"
                    :border="false"
                    resizable
                    show-overflow
                    show-header-overflow
                    highlight-hover-row
                    align="center"
                    :column-config="{ resizable: true }"
                    height="500px"
                    size="small"
                    :stripe="true"
                    :sort-config="{ trigger: 'cell', multiple: true }"
                    :scroll-y="{ mode: 'wheel', gt: 50, oSize: 30 }"
                    :empty-render="{ name: 'NotData' }"
                    :filter-config="{ showIcon: false }"
                    :export-config="{}"
                    :checkbox-config="{ range: true }"
                  >
                    <!-- <vxe-column
                      field="multi"
                      fixed="left"
                      type="checkbox"
                      title="批量"
                      min-width="60"
                    ></vxe-column> -->

                    <vxe-column
                      min-width="70"
                      field="stock_name"
                      title="股票名"
                    >
                      <template #default="{ row }">
                        <div class="d-flex justify-start">
                          {{ row.stock_name }}
                        </div>
                      </template>
                    </vxe-column>

                    <vxe-column
                      min-width="70"
                      field="stock_code"
                      title="股票代码"
                    >
                      <template #default="{ row }">
                        <div class="d-flex justify-start">
                          {{ row.stock_code }}
                        </div>
                      </template>
                    </vxe-column>

                    <vxe-column
                      title="操作"
                      type="seq"
                      width="80"
                      :resizable="false"
                      show-overflow
                    >
                      <template #default="{ row }">
                        <div>
                          <a
                            href="javascript:;"
                            @click="deleteStock(row)"
                            class="text-xs font-weight-normal text-typo"
                            >删除</a
                          >
                        </div>
                      </template>
                    </vxe-column>
                  </vxe-table>
                </div>
                <!-- 策略回测 -->
                <div
                  v-show="main_tab == 2"
                  class="pa-1"
                  style="border: 1px solid #ccc"
                >
                  <Strategy :stockList="stockList"></Strategy>
                </div>
              </div>
              <div style="border: 1px solid #ccc" class="pa-2">
                <div style="color: #eb5454">注意</div>
                <div style="font-size: 12px">
                  1.所有策略和算法均基于历史数据，不代表未来股价走势<br />
                  2.回测结果仅供参考和研究，不构成投资建议<br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import Firm from "@/api/firm.js";
import Strategy from "@/components/Firm/Strategy.vue";
import BackTest from "@/components/Firm/BackTest.vue";
import XEUtils from "xe-utils";
import StockAdd from "@/components/Firm/StockAdd.vue";

export default {
  name: "Manage",
  components: { StockAdd, Strategy, BackTest },
  data() {
    return {
      main_tab: "1",
      showBackTest: false,
      showPage: true,
      pageHeight: 0,

      firmInfo: {
        name: "加载中",
      },
      filterName: "",
      stockList: [],
    };
  },
  computed: {
    list() {
      const filterName = XEUtils.toValueString(this.filterName)
        .trim()
        .toLowerCase();
      if (filterName) {
        const filterRE = new RegExp(filterName, "gi");
        const searchProps = ["stock_name", "stock_code"];
        const rest = this.stockList.filter((item) =>
          searchProps.some(
            (key) =>
              XEUtils.toValueString(item[key])
                .toLowerCase()
                .indexOf(filterName) > -1
          )
        );
        return rest;
      }
      return this.stockList;
    },
  },
  beforeDestroy() {
    Bus.$off("showFirmBackTest");
    Bus.$off("refreshFirmStockList");
  },
  mounted() {
    this.pageHeight = document.body.offsetHeight;
    //显示回测
    Bus.$on("showFirmBackTest", (e) => {
      console.log("显示回测", e);
      this.showBackTest = false;
      setTimeout(() => {
        this.showBackTest = true;
      }, 100);
    });
    Bus.$on("refreshFirmStockList", (e) => {
      Firm.getFirmDetail(this.appStore.currentFirmDetail.id).then((res) => {
        this.firmInfo = res.data.firm;
        this.stockList = res.data.items;
        this.appStore.currentFirmDetail.stockList = res.data.items;
      });
    });
    this.getFirmDetail();
  },
  methods: {
    getFirmDetail() {
      this.showLoading("正在获取组合详情");
      Firm.getFirmDetail(this.appStore.currentFirmDetail.id).then((res) => {
        this.firmInfo = res.data.firm;
        this.stockList = res.data.items;
        this.appStore.currentFirmDetail.stockList = res.data.items;
        this.hideLoading();
      });
    },
    addStock() {
      this.$layer.iframe({
        content: {
          content: StockAdd,
          parent: this,
          data: { iframeData: {} },
        },
        area: ["600px", "600px"],
        title: "添加成分股",
        maxmin: false,
        shade: true,
        shadeClose: false,
        cancel: () => {
          //关闭弹窗事件
        },
      });
    },
    deleteStock(row) {
      this.$layer.confirm("确定要删除该成分股吗？", (layerid) => {
        this.showLoading("删除中，请稍候");
        Firm.deleteStock(row.id)
          .then((res) => {
            this.showToast("删除成功", "success", 3000);
            Bus.$emit("refreshFirmStockList", true);
          })
          .catch((err) => {
            console.log(err.msg);
            this.showToast(err.msg, "danger", 3000);
          })
          .finally(() => {
            this.hideLoading();
          });
        this.$layer.close(layerid);
      });
    },
  },
};
</script>

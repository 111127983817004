<template>
  <div
    class="v-application bg-white"
    style="width: 100%; display: flex; justify-content: center"
  >
    <div
      style="min-height: 100% !important; min-width: 500px; width: 80%"
      class="pt-2"
    >
      <div class="d-flex align-center justify-center">
        <label
          class="mr-3 white-space-nowrap"
          style="width: 88px; font-size: 14px"
          >股票名</label
        >
        <StockSelect
          ref="stockSelect"
          :returnItem="true"
          @getItem="getItem"
          placeholder="股票名称/代码/缩写"
          :showIcon="true"
          class="mr-3"
          :disableArr="[]"
        ></StockSelect>
      </div>

      <div class="my-4">
        <!-- <vxe-button status="primary" @click="submit">提交</vxe-button> -->
        <vxe-button status="warning" @click="cancel">取消</vxe-button>
      </div>
    </div>
  </div>
</template>
<script>
import Firm from "@/api/firm.js";
import Bus from "@/components/Bus/bus.js";
import StockSelect from "@/components/CommonSelect/StockSelect.vue";

export default {
  name: "FirmAdd",
  components: { StockSelect },
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  mounted() {},
  beforeDestroy() {},
  data() {
    return {};
  },
  methods: {
    getItem(item) {
      if (item) {
        console.log(item);
        this.submit(item);
        setTimeout(() => {
          this.$refs.stockSelect.clearInput();
        }, 100);
      }
    },
    submit(item) {
      this.showLoading("添加中，请稍候");
      Firm.addStock({
        firm_id: this.appStore.currentFirmDetail.id,
        stock_code: item.code,
        type: item.type,
      })
        .then((res) => {
          this.showToast("添加成功，您可以关闭弹窗或继续添加", "success", 3000);
          Bus.$emit("refreshFirmStockList", true);
        })
        .catch((err) => {
          console.log(err.msg);
          this.showToast(err.msg, "danger", 3000);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  computed: {},
  watch: {},
};
</script>
<style scoped="scoped"></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mt-4 pl-6"},[_vm._v("策略回测报告")]),_c('h4',{staticClass:"mt-2 pl-8 text--secondary"},[_vm._v(" 业绩基准："),_c('u',[_vm._v(_vm._s(this.appStore.firmBackTestData.benchmark))]),_vm._v(" ，回测起始日期："),_c('u',[_vm._v(_vm._s(this.appStore.firmBackTestData.start_date))]),_vm._v(" ，回测结束日期："),_c('u',[_vm._v(_vm._s(this.appStore.firmBackTestData.end_date))])]),_c('div',{staticClass:"mt-2 px-6"},[_c('h3',{staticClass:"mt-2"},[_vm._v("回测指标概况(策略表现/基准表现)")]),_c('div',{staticClass:"mt-2 px-0 d-flex justify-space-between flex-sm-wrap"},_vm._l((_vm.report_Arr),function(item){return _c('div',{staticClass:"d-flex justify-space-between py-1",staticStyle:{"width":"200px","font-size":"13px","margin-bottom":"1px"}},[_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(item)+" ")]),_c('span',[_c('span',{staticStyle:{"font-size":"14px"},style:(_vm.$stockHelpers.getColor(
                _vm.appStore.firmBackTestData.report_table['策略'][item]
              ))},[_vm._v(" "+_vm._s(_vm.$stockHelpers.getPercent( _vm.appStore.firmBackTestData.report_table["策略"][item] ))+" ")]),_vm._v("/ "),_c('span',{staticStyle:{"font-size":"11px"},style:(_vm.$stockHelpers.getColor(
                _vm.appStore.firmBackTestData.report_table['benchmark'][item]
              ))},[_vm._v(" "+_vm._s(_vm.$stockHelpers.getPercent( _vm.appStore.firmBackTestData.report_table["benchmark"][item] ))+" ")])])])}),0)]),_c('TradeChart'),_c('div',{staticClass:"mt-4 px-6"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h3',{staticClass:"my-2"},[_vm._v("交易详情")]),_c('div',[_c('span',{staticClass:"mr-2 text-secondary",staticStyle:{"font-size":"14px"}},[_vm._v("筛选股票")]),_c('vxe-select',{attrs:{"placeholder":"筛选股票","size":"mini"},model:{value:(_vm.tradeRecordSelect),callback:function ($$v) {_vm.tradeRecordSelect=$$v},expression:"tradeRecordSelect"}},_vm._l(([{ stock_code: 'all', stock_name: '全部' }].concat(
              _vm.appStore.currentFirmDetail.stockList
            )),function(item){return _c('vxe-option',{key:item.stock_code,attrs:{"value":item.stock_code,"label":((item.stock_name) + "(" + (item.stock_code) + ")")}})}),1)],1)]),_c('vxe-table',{ref:"firmTradeTable",attrs:{"border":"","show-header-overflow":"","show-overflow":"","highlight-hover-row":"","data":_vm.tradeTable,"size":"mini","stripe":"","max-height":"400","filter-config":{ showIcon: false },"sort-config":{
        trigger: 'cell',
        defaultSort: { field: 'datein', order: 'desc' },
      }}},[_c('vxe-table-column',{attrs:{"type":"seq","title":"序号","width":"50"}}),_c('vxe-table-column',{attrs:{"field":"stock_code","title":"股票","width":"180","filters":[{ data: '' }],"filter-method":function (value) { return _vm.$tableHelpers.filterInputMethod(value, 'stock_code'); }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
      var $panel = ref.$panel;
      var column = ref.column;
return [_vm._l((column.filters),function(option,index){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.data),expression:"option.data"}],key:index,staticClass:"my-input",attrs:{"type":"type","placeholder":"按回车确认筛选"},domProps:{"value":(option.data)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(option, "data", $event.target.value)},function($event){return $panel.changeOption($event, !!option.data, option)}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return $panel.confirmFilter()}}})]})]}},{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.stock_name)+"("+_vm._s(row.stock_code)+") ")]}}])}),_c('vxe-table-column',{attrs:{"field":"status","title":"状态","width":"50","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{style:({ color: row.status == 1 ? '#FFB02E' : '#606266' })},[_vm._v(" "+_vm._s(row.status == 1 ? "持有" : "已平仓")+" ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"datein","title":"买入时间","width":"80","sortable":""}}),_c('vxe-table-column',{attrs:{"field":"pricein","title":"买入价","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.pricein ? row.pricein.toFixed(2) : "-")+" ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"dateout","title":"卖出时间","width":"80","sortable":""}}),_c('vxe-table-column',{attrs:{"field":"priceout","title":"卖出价格","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row.priceout ? row.priceout.toFixed(2) : "-")+" ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"returns","title":"收益率","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end",style:(_vm.$stockHelpers.getColor(row['returns']))},[_vm._v(" "+_vm._s((row["returns"] * 100).toFixed(2))+"% ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"nbars","title":"持有天数","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" "+_vm._s(row["nbars"] ? row["nbars"] : "持有中")+" ")])]}}])})],1),(_vm.tradeRecordSelectData)?_c('div',[_c('h3',{staticClass:"my-2"},[_vm._v("成分股交易图")]),_c('div',[_c('StockPriceWithTrade',{attrs:{"stockData":_vm.tradeRecordSelectData}})],1)]):_vm._e(),_c('div',[_c('h3',{staticClass:"my-2"},[_vm._v("策略累计收益图")]),_c('NetValue')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
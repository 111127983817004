<template>
  <v-autocomplete
    ref="autocomplete"
    background-color="rgba(255,255,255,.9)"
    color="rgba(0,0,0,.6)"
    rounded-sm
    outlined
    v-model="model"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    hide-details
    :placeholder="placeholder"
    :clearable="!readonly"
    :readonly="readonly"
    :multiple="multiple"
    class="
      input-icon
      font-size-input
      placeholder-lighter
      input-alternative
      border border-radius-sm
    "
    style="width: 180px"
    :search-throttle="500"
  >
    <template slot="prepend-inner" v-if="showIcon">
      <v-icon color="rgba(0,0,0,.6)" size=".875rem"> fas fa-search </v-icon>
    </template>

    <template v-slot:item="data">
      <template>
        <v-list-item-content
          v-if="!data.item.disabled"
          class="pa-2"
          style="font-size: 14px"
        >
          {{ data.item.showText }}
        </v-list-item-content>
        <v-list-item-content
          v-else
          class="bg-black pa-2"
          style="font-size: 14px"
        >
          {{ data.item.showText }}
        </v-list-item-content>
      </template>
    </template>

    <template slot="append"> &nbsp; </template>
  </v-autocomplete>
</template>
<script>
import Bus from "@/components/Bus/bus.js";
import Stock from "@/api/stock.js";
import { throttle } from "lodash";
export default {
  name: "stock-select",
  props: {
    name: "",
    code: "",
    value: "",
    placeholder: "",
    returnCode: false,
    returnItem: false,
    showIcon: false,
    readonly: false,
    multiple: false,
  },
  data() {
    return {
      model: "",
      search: "",
      items: [],
      isLoading: false,
    };
  },
  mounted() {
    this.search = this.name;
    if (this.value) {
      this.model = this.value;
      console.log(this.value);
    }
  },
  methods: {
    submit() {},
    clearInput() {
      console.log("clearInput");
      this.model = null;
      this.items = []; //再将选中的值清空
    },
    getStockList: throttle(async function (val) {
      this.isLoading = true;
      Stock.searachStock(val)
        .then((res) => {
          let arr = [];
          res.data.forEach((item, index) => {
            arr.push({
              text: `${item.name} | ${item.code} (${item.pinyin})`,
              showText: `${item.name} | ${item.code}`,
              value: item.code,
              disabled: false,
              item: item,
              type: item.type,
            });
          });
          this.items = this.items.concat(arr);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 500),
  },
  watch: {
    // code(val){
    // 	console.log(val);
    // 	this.model=val;
    // },
    model(val) {
      this.search = this.name;
      if (this.returnCode) {
        let resCode = "";
        this.items.forEach((item) => {
          if (item.value == val) {
            resCode = item.item.code;
            return;
          }
        });
        this.$emit("getCode", resCode);
      }
      if (this.returnItem) {
        let resItem = "";
        this.items.forEach((item) => {
          if (item.item.code == val) {
            resItem = item.item;
          }
        });
        if (resItem) {
          this.$emit("getItem", resItem);
        }
      }
      this.$emit("input", val);
    },
    search(val) {
      if (val) {
        let haveOld = false;
        this.items.forEach((item) => {
          if (item.text.indexOf(val) != -1) {
            haveOld = true;
          }
        });
        if (!haveOld) {
          this.getStockList(val);
        }
      }
    },
  },
};
</script>
